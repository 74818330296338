<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Level Chest Management" class="mb-3" />
      </b-col>
    </b-row>
    <data-create-popup title="Create Level Chest"
      :schema="create_schema" :default-data="{image: null, name: null, type: null}"
      :create-data-fn="createItem" @create="$refs.group_list.getList()"
    />
    <data-table-ssr id="group_list" ref="group_list"
      :columns="fields" :get-list-fn="getList"
      :to-edit-fn="toEditPage"     
    >
      <template #slot_rewards="{slotData}">
        
        <p v-if="slotData.boosters.length">{{ convertRewardToText(slotData.boosters, 'booster_id') }}</p>
        <p v-if="slotData.support_items.length">{{ convertRewardToText(slotData.support_items, 'support_item_id') }}</p>
        <p v-if="slotData.coin">{{ slotData.coin }} Coin</p>
        <p v-if="slotData.unlimited_lives">{{ slotData.unlimited_lives }}m Unlimited Lives</p>
      </template>
    </data-table-ssr>
  </div>
</template>

<script>
import service from "../service";
const create_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Level', field: 'level_number', validate: { required: true } },
    ]
  }
];
const fields = [
  { label: 'Level', field: 'level_number' },
  { label: 'Rewards', field: 'rewards', input_type: 'slot' },
];
export default {

  data() {
    return {
      create_schema,
      fields,
    };
  },

  created() {
  },

  methods: {
    async getList({ limit, page, query }) {
      let response_data = await service.getList({
        query: JSON.stringify({ ...query }),
        page, limit,
        populate: JSON.stringify(['boosters.booster_id', 'support_items.support_item_id'])
      });
      let list = [], total = 0;
      if (response_data) {
        list = response_data.list;
        total = response_data.total;
        this.total = total;
      }
      return { list, total };
    },
    async createItem(data) {
      let new_item = await service.create({ ...data });
      return new_item;
    },
    toEditPage({ _id }) {
      this.$router.push({ name: 'level-chest-detail', params: { id: _id } });
    },
    convertRewardToText(rewards, field){
      let text = ''
      rewards.forEach((item)=>{
        text += `${item.quantity} ${item[field].name} -`
      })
      text = text.slice(0, -2);
      return text
    }
  },
};
</script>
